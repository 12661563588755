.s-gallery {
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (min-width: @screen-sm) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  &__inner {
    // padding: 0 calc(~"1rem + 15px");
  }

  &__item {
    background-color: #eee;
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}