.s-btn {
  position: relative;
  display: inline-block;
  min-width: 10rem;
  background-color: @button-main-bg-color;
  background-image: none;
  color: @text-color--darker;
  margin: 0;
  padding: 1em 3em;
  border-radius: 0.1em;
  font-family: @font-family--heading;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.1em;
  line-height: 1.2em;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  cursor: pointer;
  touch-action: manipulation;
  user-select: none;
  overflow: hidden;
  box-sizing: border-box;
  white-space: nowrap;
  z-index: 1;

  &:hover,
  &:active {
    background-color: #f7a518;
  }
}