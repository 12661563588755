.s-feedback {
  background-color: @bg-color--lighter;
  color: @text-color--lightest;
  padding-top: 2rem;
  padding-bottom: 2rem;

  &__title {
    color: @text-color--lighter;
    font-weight: 400;
    font-size: 1.5rem;
    margin-bottom: 2rem;

    @media (min-width: @screen-sm) {
      margin-bottom: 0;
      line-height: 3rem;
      margin-bottom: 1rem;
    }

    @media (min-width: @screen-md) {
      margin-bottom: 0;
    }
  }

  &__form-group {
    margin-bottom: 1rem;

    @media (min-width: @screen-sm) {
      margin-bottom: 0;
    }
  }
}