.s-advantages {
  background-color: @bg-color--default;

  &__info {
    margin-bottom: 3rem;
  }

  &__title {
    margin-bottom: 5rem;
  }

  &__text {
    max-width: 30em;
  }

  &__list {
    list-style-type: none;
    padding-left: 15px;
    padding-right: 15px;

    @media (min-width: @screen-sm) {
      padding-top: 2rem;
    }

    @media (min-width: @screen-md) {
      margin-right: auto;
      margin-left: auto;
    }
  }

  &__item {
    color: @text-color--darker;
    padding: 0;
    text-align: center;

    figure {
      margin: 0;
      min-height: 8rem;
    }

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    @media (min-width: @screen-sm) {
      margin-bottom: 2rem;

      figure {
        min-height: 10rem;
      }
    }
  }

  &__item-cover {
    width: 100%;
    height: 3rem;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 50%;
    margin-bottom: 1rem;

    @media (min-width: @screen-sm) {
      background-position: 50% 50%;
      height: 4rem;
    }

    &--concrete {
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/concrete.svg');
    }

    &--delivery {
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/delivery.svg');
    }

    &--standart {
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/standart.svg');
    }

    &--payment {
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/payment.svg');
      background-size: contain;
    }

    &--order {
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/order.svg');
    }

    &--autopark {
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/autopark.svg');
    }
  }

  &__item-caption {
    font-family: @font-family--heading;
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1.3em;
    max-width: 95%;
    margin: auto;

    @media (min-width: @screen-sm) {
      max-width: 80%;
      font-size: 1rem;
      font-weight: 800;
    }
  }
}