@font-face {
  font-family: 'Spectral';
  src: url('../fonts/spectral-regular.woff2') format('woff2'),
       url('../fonts/spectral-regular.woff') format('woff'),
       url('../fonts/spectral-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/opensans-regular.woff2') format('woff2'),
       url('../fonts/opensans-regular.woff') format('woff'),
       url('../fonts/opensans-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/opensans-semibold.woff2') format('woff2'),
       url('../fonts/opensans-semibold.woff') format('woff'),
       url('../fonts/opensans-semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/opensans-extrabold.woff2') format('woff2'),
       url('../fonts/opensans-extrabold.woff') format('woff'),
       url('../fonts/opensans-extrabold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

