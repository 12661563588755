.s-main-subtitle {
  color: @text-color--darker;
  font-size: 1.25rem;
  font-weight: 800;
  position: relative;
  margin-bottom: 4rem;

  &::after {
    position: absolute;
    content: '';
    height: 3px;
    width: 3rem;
    background-color: @bg-color--lighter;
    left: 1px;
    bottom: -1rem;
  }

  @media (min-width: @screen-sm) {
    font-size: 1.75rem;

    &::after {
      height: 5px;
    }
  }

  @media (min-width: @screen-md) {
    font-size: 2rem;
  }

  &--light {
    color: @text-color--lighter;
  }
}