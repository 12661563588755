.s-page-footer {
  background-color: @bg-color--darker;
  padding-top: 3rem;
  padding-bottom: 2rem;

  &__top {
    padding-bottom: 1rem;
  }

  &__address {
    font-family: @font-family--heading;
    font-size: 0.875rem;
    color: @text-color--lighter;
    line-height: 1.6em;
    margin-top: 2rem;

    @media (min-width: @screen-sm) {
      margin-top: 0;
    }
  }

  &__title {
    color: @text-color--lightest;
    font-weight: 800;
    margin-bottom: 2rem;
  }

  &__text {
    color: @text-color--lighter;
    font-size: 0.875em;
    line-height: 1.5em;
    margin-bottom: 2rem;

    span {
      color: @text-color--lightest;
    }

    @media (min-width: @screen-sm) {
      margin-bottom: 0;
    }
  }

  &__contact-list {
    list-style-type: none;
  }

  &__contact-link {
    color: @text-color--lighter;
    font-size: 0.875rem;
    display: inline-block;
    font-weight: 800;
    letter-spacing: 0.025em;
  }

  &__bottom {
    border-top: 1px solid @bg-color--lighter;
    padding-top: 2rem;
  }

  &__nav {
    color: @text-color--lighter;
    text-align: right;
  }

  &__nav-list {
    list-style-type: none;
    display: table;
    margin-left: auto;
    margin-bottom: 0;
  }

  &__nav-item {
    display: inline-block;
    position: relative;

    &:not(:last-child) {

      .s-page-footer__nav-link {
        padding-right: 0.75rem;
        border-right: 1px solid @bg-color--lighter;
      }
    }
  }

  &__nav-link {
    display: inline-block;
    color: currentColor;
    font-size: 0.875rem;
    padding-left: 0.75rem;

    &:hover {
      opacity: 0.5;
    }
  }

  &__copyright {
    color: @text-color--lighter;
    font-family: @font-family--heading;
    vertical-align: middle;
    font-size: 0.875rem;
  }
}