.s-section {
  // padding: 1rem;
  padding-top: 3rem;
  padding-bottom: 3rem;

  @media (min-width: @screen-sm) {
    padding: 1rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}