.s-form {

  &__label,
  label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    border: 0;
  }

  &__input,
  input {
    min-height: 3rem;
    border: 0;
    padding: 0.25rem 1rem;
    border-radius: 0.1em;
    font-family: @font-family--heading;
    width: 100%;
    max-width: 24rem;
    box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.50);
    font-size: 0.875rem;
  }

  textarea {
    width: 100%;
    border: 0;
    padding: 0.25rem 1rem;
    border-radius: 0.1em;
    font-family: @font-family--heading;
    width: 100%;
    max-width: 24rem;
    box-shadow: inset 0 1px 2px 0 rgba(0,0,0,0.50);
    font-size: 0.875rem;
    resize: none;
    padding-top: 1rem;
  }

  &__submit-btn {
    width: 100%;
    min-height: 3rem;
    max-width: 24rem;
    padding: 0.25rem 1rem;
    transition: all 250ms ease-in-out;
  }
}