/*
LESS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: @icon-home-width;
}

The large array-like variables contain all information about a single icon
@icon-home: x y offset_x offset_y width height total_width total_height image_path name;

At the bottom of this section, we provide information about the spritesheet itself
@spritesheet: width height image @spritesheet-sprites;
*/
@ic-person-add-name: 'ic_person_add';
@ic-person-add-x: 94px;
@ic-person-add-y: 0px;
@ic-person-add-offset-x: -94px;
@ic-person-add-offset-y: 0px;
@ic-person-add-width: 110px;
@ic-person-add-height: 80px;
@ic-person-add-total-width: 204px;
@ic-person-add-total-height: 100px;
@ic-person-add-image: '../img/sprite-png.png';
@ic-person-add: 94px 0px -94px 0px 110px 80px 204px 100px '../img/sprite-png.png' 'ic_person_add';
@ic-share-name: 'ic_share';
@ic-share-x: 0px;
@ic-share-y: 0px;
@ic-share-offset-x: 0px;
@ic-share-offset-y: 0px;
@ic-share-width: 90px;
@ic-share-height: 100px;
@ic-share-total-width: 204px;
@ic-share-total-height: 100px;
@ic-share-image: '../img/sprite-png.png';
@ic-share: 0px 0px 0px 0px 90px 100px 204px 100px '../img/sprite-png.png' 'ic_share';
@spritesheet-width: 204px;
@spritesheet-height: 100px;
@spritesheet-image: '../img/sprite-png.png';
@spritesheet-sprites: @ic-person-add @ic-share;
@spritesheet: 204px 100px '../img/sprite-png.png' @spritesheet-sprites;

/*
The provided classes are intended to be used with the array-like variables

.icon-home {
  .sprite-width(@icon-home);
}
.icon-email {
  .sprite(@icon-email);
}

Example usage in HTML:

`display: block` sprite:
<div class="icon-home"></div>

To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

// CSS
.icon {
  display: inline-block;
}

// HTML
<i class="icon icon-home"></i>
*/
.sprite-width(@sprite) {
  width: extract(@sprite, 5);
}

.sprite-height(@sprite) {
  height: extract(@sprite, 6);
}

.sprite-position(@sprite) {
  @sprite-offset-x: extract(@sprite, 3);
  @sprite-offset-y: extract(@sprite, 4);
  background-position: @sprite-offset-x @sprite-offset-y;
}

.sprite-image(@sprite) {
  @sprite-image: extract(@sprite, 9);
  @sprite-image-bare: ~`"@{sprite-image}".slice(1, -1)`;
  background-image: url(@sprite-image-bare);
}

.sprite(@sprite) {
  .sprite-image(@sprite);
  .sprite-position(@sprite);
  .sprite-width(@sprite);
  .sprite-height(@sprite);
}

/*
The `.sprites` mixin generates identical output to the CSS template
  but can be overridden inside of LESS

This must be run when you have at least 2 sprites.
  If run with a single sprite, then there will be reference errors.

.sprites(@spritesheet-sprites);
*/
.sprites(@sprites, @i: 1) when (@i <= length(@sprites)) {
  @sprite: extract(@sprites, @i);
  @sprite-name: e(extract(@sprite, 10));
  .@{sprite-name} {
    .sprite(@sprite);
  }
  .sprites(@sprites, @i + 1);
}
