.s-about {
  background-color: @bg-color--gray;

  &__info {
    margin-bottom: 3rem;

    p {
      max-width: 27em;
    }
  }
}