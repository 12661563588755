a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

[role='button'] {
  cursor: pointer;
}

html {
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
          font-smoothing: antialiased;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  min-width: 320px;
  margin: 0;
  font-family: @font-family;
  color: @text-color;
  // font-size: @font-size;
  // line-height: @line-height;
  font-size: @font-size--desktop;
  line-height: @line-height--desktop;

  // @media (min-width: @screen-lg) {
  //   font-size: @font-size--desktop;
  //   line-height: @line-height--desktop;
  // }
}

img {
  box-sizing: content-box;
  max-width: 100%;
  height: auto;
  border: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol {
  margin: 0;
  padding: 0;
}

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 1rem;
}

address {
  font-style: normal;
}

li {
  line-height: inherit;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;

  @media (min-width: @screen-sm) {
    margin-left: 1.5rem;
  }

  & + dt {
    margin-top: (@line-height / 2);
  }
}

h1,
h2,
h3,
h4,
a,
button,
strong {
  font-weight: 400;
  font-style: normal;
  font-family: @font-family--heading;
  line-height: @line-height--heading;
}

h1 {
  font-size: @font-size--h1;
}

h2 {
  font-size: @font-size--h2;
}

h3 {
  font-size: @font-size--h3;
}

h4 {
  font-size: @font-size--h4;
}

small {
  font-size: @font-size--small;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
}

a {
  text-decoration: none;
  background-color: transparent;
  transition: all 0.25s ease-in-out 0s;
}

button {
  background-color: inherit;
  border: none;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid @border-color;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
textarea {
    -webkit-appearance: none;
}

input[type="text"],
textarea {
  text-transform: capitalize;
}

blockquote {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
}

code,
kbd,
pre,
samp {
  font-family: @font-family--monospace;
}

code {
  padding: 0.06em 0.3em;
  color: @text-color;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: @border-radius;
}

kbd {
  padding: 0.06em 0.3em;
  color: @text-color;
  background-color: @gray-lightest;
  border-radius: @border-radius;

  kbd {
    padding: 0;
  }
}

pre {
  display: block;
  width: 100%;
  overflow-x: auto;
  tab-size: 2;
}

.prevent-scroll {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}