.s-main-slider {
  background: transparent;
  position: relative;

  &__navs-btn {
    width: 38px;
    height: 38px;
    vertical-align: middle;

    .owl-carousel .owl-nav .owl-next & {
      transform: rotate3d(0, 0, 1, 180deg);
    }
  }

  & .owl-nav {
    position: absolute;
    top: -2.5rem;
    left: 50%;
    margin-top: 0;
    transform: translate(-50%,-50%);
  }

  & .owl-nav [class*='owl-'] {
    background: transparent;
    padding: 0;

    &:hover,
    &:active {
      background: transparent;
    }
  }
}