.s-promo {
  background-color: @bg-color--default;
  color: @text-color--lightest;
  position: relative;
  min-height: 20rem;
  z-index: 1;

  @media (min-width: @screen-sm) {
    min-height: 30rem;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 7rem;

    @media (min-width: @screen-sm) {
      width: @screen-sm;
      margin-left: calc(~"(100% - 750px) / 2");
      padding-top: 10rem;
    }

    @media (min-width: @screen-md) {
      width: @screen-md;
      margin-left: calc(~"(100% - 970px) / 2");
    }

    @media (min-width: @screen-lg) {
      width: @screen-lg;
      margin-left: calc(~"(100% - 1170px) / 2");
    }
  }

  &__title {
    font-weight: 800;
    font-size: 1.75rem;
    line-height: 1.2em;
    margin-bottom: 1rem;
    max-width: 12em;

    @media (min-width: @screen-sm) {
      font-size: 3rem;
      line-height: 1em;
      margin-left: -0.125rem;
    }
  }

  &__desc {
    max-width: 30em;
    line-height: 1.2em;
    margin-bottom: 2rem;
    font-weight: 400;
    max-width: 30em;

    @media (min-width: @screen-sm) {
      font-size: 1.25rem;
    }
  }

  &__slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    & .owl-nav.disabled + .owl-dots {
      position: absolute;
      bottom: 2rem;
      left: 50%;
      transform: translate3d(-50%,0,0);
      z-index: 15;
    }
  }

  &__slider-item {
    // height: 100%;
    height: 30rem;
    width: 100%;

    @media (min-width: @screen-sm) {
      height: 37rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}