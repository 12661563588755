.s-table {
  width: 100%;
  font-family: @font-family--heading;
  border-collapse: collapse;
  text-align: center;
  font-weight: 600;

  th {
    font-size: 0.75rem;
    font-weight: 800;
    background-color: transparent;
    color: @text-color--darker;
    padding: 1rem;
    border: 1px solid @bg-color--gray;

    @media (min-width: @screen-sm) {
      font-size: 1rem;
    }
  }

  tbody {
    font-size: 0.875rem;

    tr {

      &:nth-of-type(even) {
        background-color: #f6f6f6;
      }

      &:nth-of-type(odd) {
        background-color: #f1f1f1;
      }
    }

    td {
      border: 2px solid @bg-color--gray;
      padding: 0.75rem;
    }
  }
}