.s-request-a-price {
  background-color: #012f61;
  color: @text-color--lightest;

  &__desc {
    margin-bottom: 2rem;
  }

  &__contact-list {
    list-style-type: none;
    margin-bottom: 2rem;

    @media (min-width: @screen-sm) {
      margin-bottom: 0;
    }
  }

  &__contact-item {

  }

  &__contact-link {
    font-size: 1rem;
    font-weight: 600;
    color: currentColor;
    color: @yellow;
  }

  &__form {

  }

  &__form-group {
    margin-bottom: 1rem;

    input,
    textarea {
      background-color: rgba(45,121,196,0.5);
      font-weight: 600;

      &::placeholder {
        color: @text-color--lighter;
      }
    }
  }
}