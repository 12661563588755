.s-dropcap {
  height: 65px;
  margin-right: 7px;
  font-size: 74px;
  line-height: 68px;
  float: left;
  text-indent: -3px;
  color: @text-color;
  font-weight: 700;

  @media (min-width: @screen-lg) {
    height: 70px;
    font-size: 90px;
    line-height: 78px;
    text-indent: -5px;
  }
}