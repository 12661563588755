.s-delivery-and-payment {
  background-color: @bg-color--default;

  &__info {
    margin-bottom: 2rem;

    p {
      max-width: 32em;
    }
  }

  &__desc {
    font-family: @font-family--heading;
    font-weight: 600;
    color: #2d79c4;
    margin-bottom: 3rem;
    line-height: 1.45em;
  }

  &__list-item {
    list-style-type: none;

    @supports (display: flex) {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
    }

    @media (min-width: @screen-sm) {

      @supports (display: flex) {
        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
      }
    }
  }

  &__item {
    background-color: @bg-color--gray;
    background-repeat: no-repeat;
    background-size: 230px;
    padding: 1.5rem 1rem;
    min-height: 18rem;
    background-clip: padding-box;
    border-radius: 0.1em;
    margin-bottom: 1rem;

    @supports (display: flex) {
      min-height: auto;
      margin: 0 15px;
      margin-bottom: 1rem;
    }

    @media (min-width: @screen-sm) {
      padding: 2rem 3rem;
      background-size: 230;
      margin-bottom: 0;

      @supports (display: flex) {
        margin-bottom: 0;
      }
    }

    @media (min-width: @screen-sm) {}

    &--delivery {
      background-position: 40% 50%;
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/box.svg');
    }

    &--payment {
      background-position: 50% 50%;
      background-image: data-uri('image/svg+xml;charset=UTF-8', '../img/credit-card.svg');
    }
  }

  &__subtitle {
    color: @text-color--darker;
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;

    &::before {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      content: '';
      height: 3px;
      width: 2rem;
      background-color: @bg-color--lighter;
      margin-right: 1rem;
    }
  }
}