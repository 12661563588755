.s-price {
  background-color: @bg-color--gray;

  &__info {
    margin-bottom: 3rem;
  }

  p {
    max-width: 40em;
  }

  &__tabs {
    margin-bottom: 3rem;
  }

  &__tabs-list {}

  &__tabs-item {
    width: 125px;

    &.active,
    &:active,
    &:focus,
    &:hover {

      .s-price__tabs-link {
        background-color: @bg-color--gray;
        color: @text-color;

        &:hover,
        &:active,
        &:focus {
          background-color: @bg-color--gray;
          color: @text-color;
        }
      }
    }

    @media (min-width: @screen-xs) {
      width: auto;
    }
  }

  &__tabs-link {
    background-color: #f1f1f1;
    color: @gray-light;
    font-size: 1rem;
    font-weight: 600;
  }

  &__btn {
    text-align: center;
  }
}