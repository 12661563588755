.s-page-header {
  position: relative;
  width: 100%;
  box-shadow: 0 0 0 1px @text-color--darker;
  z-index: 2;

  &__info {
    background-color: @bg-color--lighter;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    color: @text-color--lightest;
    font-size: 0.875rem;
  }

  &__slogan {
    font-weight: 600;
  }

  &__contact {
    text-align: right;
  }

  &__contact-list {
    list-style-type: none;
    margin-bottom: 0;
  }

  &__contact-item {
    display: inline-block;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }

  &__contact-link {
    color: currentColor;
    display: inline-block;
  }

  &__logo {
    width: 120px;
    height: auto;

    @media (min-width: @screen-md) {
      width: 190px;
      transform: translate3d(0,-0.25rem,0);
    }
  }

  &__hamburger {
    text-align: right;
    padding-top: 0.5rem;

    @media (min-width: @screen-sm) {
      padding-top: 0.9rem;
    }

    @media (min-width: @screen-md) {
      display: none;
    }
  }

  &__nav {
    background-color: @bg-color--default;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    z-index: 2;
  }
}