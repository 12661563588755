.s-hamburger {
  display: block;
  width: 30px;
  height: 30px;
  z-index: 3;
  cursor: pointer;
  position: relative;
  padding: 0;
  margin-left: auto;

  &::before {
    position: absolute;
    content: '';
    top: -0.5rem;
    right: -0.5rem;
    bottom: -0.5rem;
    left: -0.5rem;
  }

  span {
    position: absolute;
    display: block;
    content: '';
    background-color: @bg-color--darker;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    transform: translate(-50%,-50%);

    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;
      background-color: @bg-color--darker;
      height: 2px;
      border-radius: 1px;
    }

    &::before {
      top: -0.5rem;
      right: 0;
      width: 80%;
    }

    &::after {
      bottom: -0.5rem;
      width: 60%;
      right: 0;
    }
  }
}