.s-main-nav {
  position: absolute;
  right: 1rem;
  top: 4.5rem;
  width: 280px;
  background: @bg-color--lighter;
  color: @text-color--lightest;
  z-index: 30;
  display: none;
  padding: 2rem;
  opacity: 0.98;
  box-shadow: 0 0.125rem 5px 1px @bg-color--darker;

  @media (min-width: @screen-md) {
    width: auto;
    display: block;
    position: relative;
    display: block;
    color: @text-color--darker;
    text-align: right;
    padding: 0;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 0.875em;
    background-color: @bg-color--default;
    box-shadow: none;
    top: 0;
    right: 0;
  }

  &__list {
    margin-bottom: 0;
  }

  &__item {
    display: block;

    &:not(:last-child) {
      border-bottom: 1px solid @text-color--lighter;
    }

    @media (min-width: @screen-md) {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 1.5rem;
        border-bottom: 0;
      }
    }
  }

  &__link {
    color: currentColor;
    font-weight: 600;
    display: block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    position: relative;

    @media (min-width: @screen-md) {
      display: inline-block;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      transition: all 250ms ease-in-out;

      &::before,
      &::after {
        position: absolute;
        content: '';
        background-color: @bg-color--lighter;
        height: 2px;
        width: 0;
        bottom: -0.25rem;
        transition: all 250ms ease-in-out;
      }

      &::before {
        left: 50%;
      }

      &::after {
        right: 50%;
      }

      &:hover {

        &::before {
          width: 50%;
        }

        &::after {
          width: 50%;
        }
      }
    }
  }

  &--is-active {
    display: block;
  }
}